import React from 'react'
import styled from 'styled-components';

interface INavButton {
  className?: string;
  style?: any;
  children: React.ReactNode;
  onClick?: () => void;
}

const NavButton: React.FC<INavButton> = ({ className, style = {}, children, onClick }) => {
  return (
    <NavButtonStyle className={className} style={{ ...style }} onClick={() => onClick?.()}>
      {children}
    </NavButtonStyle>
  )
};

const NavButtonStyle = styled.div`
  color: #ffffff;
  background: linear-gradient(180deg, #FABE4B 0%, #C68200 100%);
  border-radius: 40px;
  padding: 8px 20px;
  min-width: 72px;
  font-family: Kanit;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  text-shadow: 0 1px 0px #00000080;
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50px;
    padding: 1px;
    pointer-events: none;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%); 
    mask:
      linear-gradient(#000 0 0) content-box, 
      linear-gradient(#000 0 0);
    mask-composite: exclude;
    z-index: 2;
  }
`;

export default NavButton;

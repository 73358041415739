import React from 'react'
import styled from 'styled-components';
import QuestImage from 'assets/images/index/quest.webp';
import { useNavigate } from 'react-router-dom';

const AppAnnouncement: React.FC = (props) => {
  const navigate = useNavigate();
  return (
    <AnnouncementStyle>
      <div className="container">
        <img src={QuestImage} className="quest-image" />
        <div className="text">
          The Linea mainnet <span className="num">1000</span> whitelist testing event is underway. Participants can earn limited-edition <span className="num">SBTs</span>, share a 50,000 USDT reward pool.
        </div>
        <div className="action">
          <AnnouncementButonStyle onClick={() => navigate('/whitelist')}>Join!</AnnouncementButonStyle>
        </div>
      </div>
    </AnnouncementStyle>
  )
};

const AnnouncementStyle = styled.div`
  position: fixed;
  z-index: 5;
  left: 40px;
  right: 40px;
  top: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    top: 65px;
    left: 4px;
    right: 4px;
  }
  > .container {
    padding: 20px 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: #2D336E;
    border-radius: 20px;
    border: 2px solid #FFFFFF33;
    @media screen and (max-width: 768px) {
      padding: 8px 50px;
    }
    > .quest-image {
      position: absolute;
      left: 80px;
      top: 50%;
      transform: translate(-50%, -55%);
      height: auto;
      width: 91px;
      @media screen and (max-width: 768px) {
        left: 25px;
        width: 40px;
      }
    }
    > .action {
      position: absolute;
      right: 80px;
      transform: translateX(50%);
      @media screen and (max-width: 768px) {
        right: 30px;
        transform: translateX(50%) scale(0.7);
      }
    }
    > .text {
      font-family: "Jockey One";
      font-size: 20px;
      line-height: 1.4;
      color: #fff;
      text-transform: capitalize;
      position: relative;
      z-index: 2;
      -webkit-text-stroke: 0.5px #000;
      text-shadow: 1px 1px 0px #000, -1px -1px 0px #000, 1px -1px 0px #000, -1px 1px 0px #000;
      @media screen and (max-width: 768px) {
        font-size: 12px;
        line-height: 1.2;
      }
      > .num {
        font-size: 1.2em;
        color: #FABE4B;
      }
    }
  }
`;

const AnnouncementButonStyle = styled.div`
  color: #ffffff;
  background: linear-gradient(180deg, #4FDB50 0%, #198F1A 100%);
  border-radius: 40px;
  padding: 8px 20px;
  min-width: 72px;
  font-family: Kanit;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  text-shadow: 0 1px 0px #00000080;
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50px;
    padding: 1px;
    pointer-events: none;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%); 
    mask:
      linear-gradient(#000 0 0) content-box, 
      linear-gradient(#000 0 0);
    mask-composite: exclude;
    z-index: 2;
  }
`;


export default AppAnnouncement;

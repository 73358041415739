import { lineaSepolia } from "viem/chains";

export default {
  [`${lineaSepolia.id}`]: {
    Hero: {
      "address": ""
    },
    HeroEdition2: {
      "address": "0x2Ce2b3d8a3F97275f65f70f99BAdF6C72FC8ddBb"
    },
    HeroPresale: {
      "address": "0xdecbC460e2D19Be30b1AfCE1B1a2DdA35f7eCdB3"
    },
    HeroTVL: {
      "address": "0x6D83d49907Acb41FE283456FB6a6417DceD3f7fE"
    },
    Restaking: "0xa415b3De245d8B616e92A0587954dAF8e8Edb0f5",
    WETH: "0xAeb65CCDe3b88CA9095D7Cc1d8ACa82ae865AcA6",
    wstETH: "0xd9c4d0Bf3881510d9d7a883c94Bd856c4d314370",
    ezETH: "0x79A67D40f3e7396FC122268DC0136896cC7D7271",
    STONE: "0xf8b7E9A37857B01Cb9AD74e6fCaD149f44490601",
    weETH: "",
  },
} as const;

import { LayoutDefault } from 'components/layouts/LayoutDefault';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import ViewBg1 from 'assets/images/whitelist/view-bg-1.png';
import ViewBg2 from 'assets/images/airdrop/view-bg-2.jpg';
import Image1 from 'assets/images/whitelist/image-1.webp';
import WhiteListTest from 'assets/images/whitelist/white-list-test.png';
// import NoteX from 'assets/images/airdrop/x.png';
import DataClaimedPng from 'assets/images/airdrop/claimed.png';
import { useAccount, useReadContract } from 'wagmi';
import Restaking from 'types/crypto-rumble-periphery/abis/Restaking';
import { useRecoilValue } from 'recoil';
import { stateAppChainInfo } from 'states/wallet';
import { Address, zeroAddress } from 'viem';
import { fmtInt, fmtNumber } from 'utils';
import { AppButton } from 'components/app/AppButton';
import { GlobalVar } from 'constants/constants';
import { useWallet } from 'hook/useWallet';
import { PageAirdropPcP } from './pcp';
import HeroTVL from 'types/crypto-rumble-periphery/abis/HeroTVL';
import { useConnectModal } from '@rainbow-me/rainbowkit';

export const PageWhiteList: React.FC<{}> = (props) => {
  const chainInfo = useRecoilValue(stateAppChainInfo);
  const wallet = useWallet();
  const verfiyClaimInfo = useReadContract({
    abi: HeroTVL,
    address: chainInfo.HeroTVL.address,
    functionName: 'verifyClaim',
    args: [[chainInfo.WETH, chainInfo.ezETH, chainInfo.wstETH, chainInfo.STONE]],
    account: wallet.address,
    query: {
      enabled: Boolean(wallet.address),
      select(data) {
        return {
          inWhiteList: data?.[0] ?? false,
          isStaked: data?.[1] ?? false,
        };
      },
    }
  })

  const isClaimed = useReadContract({
    abi: HeroTVL,
    address: chainInfo.HeroTVL.address,
    functionName: 'claimed',
    args: [wallet.address],
    account: wallet.address,
    query: {
      enabled: Boolean(wallet.address),
      select(data) {
        return data ?? false;
      },
    }
  })

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // 平滑滾動效果
    });
  }, []);

  return (
    <LayoutDefault>
      <PageIndexStyle>
        <div className="view">
          <div className="bg">
            <img src={ViewBg1} />
            <div />
          </div>
          <div className="white-list-container">
            <div className="image-left" />
            <div className="image-right">
              <div className="white-list">
                <img src={WhiteListTest} height="41" width="auto" style={{ marginBottom: '20px' }} />
                <div className="">
                  The Linea mainnet <span className="num">1000</span> whitelist testing event is underway.
                </div>
                <div className="">
                  Participants can earn limited-edition <span className="num">SBTs</span>,
                  <br />
                  share a <span className="num">50,000</span> USDT reward pool
                </div>
                <WhiteListButton
                  inWhiteList={verfiyClaimInfo.data?.inWhiteList}
                  isStaked={verfiyClaimInfo.data?.isStaked}
                  isClaimed={isClaimed.data}
                  onClaim={wallet.requestTx(
                    async () => {
                      if (!wallet.acc.chain) return;
                      return wallet.write.writeContractAsync({
                        abi: HeroTVL,
                        address: chainInfo.HeroTVL.address,
                        functionName: 'claim',
                        args: [[chainInfo.WETH, chainInfo.ezETH, chainInfo.wstETH, chainInfo.STONE]],
                      });
                    },
                    'Claim SBT',
                    () => {
                      isClaimed.refetch();
                    }
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="view">
          <div className="bg">
            <img src={ViewBg2} />
            <div className="bg2-div" />
          </div>
          <div className="title">Rules</div>
          <div className="pcp">
            <PageAirdropPcP fontSize={16} fontWeight={500}>
              1. This event is for whitelist users.
            </PageAirdropPcP>
            <div className="hr" />
            <PageAirdropPcP fontSize={16} fontWeight={500}>
              2. You need to stake any amount of assets on Zypher Linea Mainnet.
            </PageAirdropPcP>
            <div className="hr" />
            <PageAirdropPcP fontSize={16} fontWeight={500}>
              3. Users participating in the test can receive an exclusive test SBT, which can be exchanged for a hero mystery box after the game is launched on the Zypher L3 mainnet.
            </PageAirdropPcP>
          </div>
        </div>
      </PageIndexStyle>
    </LayoutDefault>
  );
};

const DataItem: React.FC<{ img: React.ReactNode; name: string; value: string }> = (props) => {
  return (
    <DataItemStyle>
      {props.img}
      <div className="name">{props.name}</div>
      <div className="value">{props.value}</div>
    </DataItemStyle>
  );
};

const ConfirmContent: React.FC<{ img: React.ReactNode; title: string; des: string }> = (props) => {
  return (
    <ConfirmContentStyle>
      {props.img}
      <div className="title">{props.title}</div>
      <div className="des">{props.des}</div>
    </ConfirmContentStyle>
  );
};

const WhiteListButton: React.FC<{ inWhiteList?: boolean; isStaked?: boolean; isClaimed?: boolean; onClaim: ((arg?: any) => Promise<any>) | undefined }> = ({ inWhiteList = false, isStaked = false, isClaimed, onClaim }) => {
  const acc = useAccount();
  const [loading, setLoading] = useState(false);
  const { openConnectModal } = useConnectModal();

  const handleConnect = async () => {
    if (!acc.isConnected) return openConnectModal?.();
    if (!isClaimed && (inWhiteList && isStaked)) {
      try {
        setLoading(true);
        await onClaim?.();
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  }

  const renderContent = useMemo(() => {
    if (!acc.isConnected) {
      return 'Connect Wallet';
    }
    if (isClaimed) {
      return 'Claimed';
    }
    if (inWhiteList && isStaked) {
      return 'Claim SBT';
    }
    return 'Unqualified';
  }, [isClaimed, inWhiteList, isStaked, acc.isConnected]);
  return (
    <div style={{ marginTop: 'auto', position: 'relative', pointerEvents: loading ? 'none' : 'auto', opacity: loading ? 0.7 : 1 }}>
      <WhiteListButtonStyle status={renderContent} onClick={handleConnect}>
        {renderContent}
      </WhiteListButtonStyle>
      {(inWhiteList && isStaked) && !isClaimed && (<div style={{ position: 'absolute', bottom: '-27px', left: '40px', fontSize: '16px' }}>You can claim SBT!</div>)}
    </div>
  );
}

const WhiteListButtonStyle = styled.div<{ status: string }>`
  color: #121212;
  background: ${props => !['Unqualified', 'Claimed'].includes(props.status) ? 'linear-gradient(180deg, #FABE4B 0%, #C68200 100%)' : 'linear-gradient(180deg, #d3c6a1 0%, #a0926d 100%)'};
  border-radius: 40px;
  padding: 8px 24px;
  min-width: 191px;
  font-family: Kanit;
  text-align: center;
  font-weight: 800;
  font-size: 20px;
  box-sizing: border-box;
  cursor: ${props => !['Unqualified', 'Claimed'].includes(props.status) ? 'pointer' : 'default'};
  position: relative;
  text-shadow: 0 1px 0px #FFFFFF80;
  pointer-events: ${props => !['Unqualified', 'Claimed'].includes(props.status) ? 'auto' : 'none'};
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50px;
    padding: 1px;
    pointer-events: none;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%); 
    mask:
      linear-gradient(#000 0 0) content-box, 
      linear-gradient(#000 0 0);
    mask-composite: exclude;
    z-index: 2;
  }
`;


const ConfirmContentStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > .title {
    font-size: 16px;
    font-weight: 700;
  }
  > .des {
    font-size: 12px;
    font-weight: 400;
    opacity: 0.5;
  }
  > .success-imgs {
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    > .box {
      position: relative;
      z-index: 4;
    }
    > .box-s {
      position: absolute;
      z-index: 2;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
    > .box-bg {
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
`;

const DataItemStyle = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.1);
  height: 44px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 0 12px 0 8px;
  background: rgba(255, 255, 255, 0.1);
  font-size: 16px;
  font-weight: 400;
  > img {
    user-select: none;
  }
  > .value {
    color: rgba(246, 185, 69, 1);
  }
  > .name {
    color: #fff;
  }
`;

const PageIndexStyle = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  > .view {
    position: relative;
    z-index: 1;
    min-height: 700px;
    color: #fff;
    padding-top: 80px;
    padding-bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    + .view {
      padding-top: 0;
    }
    > .white-list-container {
      border-width: 16px 6px;
      border-color: #000000;
      border-style: solid;
      width: 100%;
      max-width: 1092px;
      background-color: #ffffff;
      height: 446px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      @media screen and (max-width: 768px) {
        width: 90vw;
      }
      > .image-left {
        flex: 0 1 580px;
        height: 100%;
        position: relative;
        overflow: hidden;
        background: url(${Image1}) no-repeat left / auto 100%;
        @media screen and (max-width: 768px) {
          flex: 1 1 auto;
        }
      }
      > .image-right {
        position: absolute;
        z-index: 2;
        right: 0;
        height: 100%;
        min-width: 580px;
        background: #2D336E;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 6% 100%);
        padding: 40px 30px 40px 0px;
        box-sizing: border-box;
        @media screen and (max-width: 768px) {
          min-width: auto;
          padding: 40px 10px 40px 0px;
        }
        > .white-list {
          font-family: Jockey One;
          font-size: 24px;
          line-height: 1.4;
          width: 460px;
          color: #ffffff;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-left: auto;
          text-transform: capitalize;
          gap: 10px;
          height: 100%;
          .num {
            font-size: calc(1em * 4/3);
          }
          @media screen and (max-width: 768px) {
            font-size: 14px;
            width: 75%;
            > img {
              height: 30px;
            }
          }
        }
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #fff;
          clip-path: polygon(0 0, 94% 0, 100% 100%, 0 100%);
          box-sizing: border-box;
          pointer-events: none;
          transform: translateX(calc(45px - 100%));
          @media screen and (max-width: 768px) {
            transform: translateX(calc(30px - 100%));
          }
        }
      }
    }
    > .bg {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: -1;
      pointer-events: none;
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      > div {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
        background: linear-gradient(180deg, rgba(18, 18, 18, 0.5) 0%, #121212 100%);
        &.bg2-div {
          background: linear-gradient(180deg, rgba(18, 18, 18, 1) 0%, rgba(18, 18, 18, 0.7) 50%, rgba(0, 0, 0, 1) 100%);
        }
      }
    }

    > .title {
      font-size: 32px;
      font-weight: 700;
    }
    > .des {
      font-size: 16px;
      font-weight: 400;
    }
    > .content {
      width: 100vw;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      > .datas {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin-bottom: 20px;
        flex-wrap: wrap;
        > .hr {
          background-color: rgba(255, 255, 255, 0.1);
          width: 1px;
          height: 20px;
        }
      }
      > .box {
        margin-left: 40px;
        animation: box-will-open 2.5s ease-in-out infinite;
        pointer-events: none;
        position: relative;
        z-index: 10;
        user-select: none;
      }
      @keyframes box-will-open {
        10% {
          transform: rotate(15deg) scale(0.8);
        }
        20% {
          transform: rotate(-10deg);
        }
        30% {
          transform: rotate(5deg) scale(0.9);
        }
        40% {
          transform: rotate(-5deg) scale(1.1);
        }
        50%,
        100% {
          transform: rotate(0deg) scale(1);
        }
      }
      > .progress {
        font-size: 64px;
        font-weight: 400;
        background-image: linear-gradient(180deg, #f8df74 23.44%, #f2d03e 61.46%);
        background-clip: text;
        color: transparent;
        > span {
          font-size: 64px;
          font-weight: 400;
          background-image: linear-gradient(180deg, #909af9 13.54%, #515fe4 69.27%);
          background-clip: text;
          color: transparent;
          margin-left: 20px;
        }
      }
    }
    > .pcp {
      padding-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;
      > .hr {
        width: 100%;
        height: 1px;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0) 100%);
      }
    }
  }
`;
